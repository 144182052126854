import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Collapse,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import {
  AdminPanelSettings,
  CalendarMonth,
  Description,
  Desk,
  ExpandLess,
  ExpandMore,
  Feed,
  Handyman,
  HealthAndSafety,
  Kitchen,
  ListAlt,
  LocalShipping,
  Login,
  Logout,
  Mediation,
  Menu,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DarkModeToggle from '@/components/DarkModeToggle';
import { checkGroups } from '@/consts';

const CustomLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const getCommonItems = groups => {
  const commonItems = [{ text: 'Phone Directory', href: '/phone' }];

  if (groups.includes('Reception')) {
    commonItems.push({ text: 'Absences and Vacations', href: '/vacation' });
    commonItems.push({ text: 'Printer', href: '/printer' });
  }

  if (groups.includes('Card')) commonItems.push({ text: 'Purchase Logs', href: '/purchase-logs' });

  if (groups.includes('IT')) {
    commonItems.push({ text: 'Maintenance', href: '/maintenance' });
    commonItems.push({ text: 'QR Code Generator', href: '/qrcodes' });
  }

  return commonItems;
};

const serviceItems = [
  { text: 'Service Orders (Shop)', href: '/service-orders' },
  { text: 'Service Order Schedule', href: '/service' },
];

const formItems = [
  { text: 'Quality Check Completion', href: '/forms/qcc' },
  { text: 'Requisition', href: '/forms/requisition' },
];

const getOrmontItems = groups => {
  const ormontItems = [
    { text: 'Doors', href: '/ormont/doors' },
    { text: 'Transfer List', href: '/ormont/doors/transfer' },
  ];

  if (groups.includes('SuperMod'))
    ormontItems.push({
      text: 'Request List',
      href: '/ormont/doors/request',
    });

  return ormontItems;
};

export const SubNav = ({ icon = null, primary, items }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClick = () => {
    setOpen(!open);
  };

  // Function to handle closing the sub-menu if the click is outside of the component
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // Listen for clicks outside of the component to close the sub-menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef}>
      <ListItem button onClick={handleClick}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={primary} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <ListItem component={CustomLink} href={item.href} key={index} underline="hover">
              <ListItemText inset primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

SubNav.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export const SubNavWithChildren = ({ icon, primary, children }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClick = () => {
    setOpen(!open);
  };

  // Function to handle closing the sub-menu if the click is outside of the component
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // Listen for clicks outside of the component to close the sub-menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef}>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </div>
  );
};

SubNavWithChildren.propTypes = {
  icon: PropTypes.element.isRequired,
  primary: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const userProps = {
  username: '',
  email: '',
  is_superuser: false,
  is_staff: false,
  full_name: '',
};

export const Sidebar = ({ isAuthenticated, user = userProps, groups, children = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startX, setStartX] = useState(null);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const handleTouchStart = useCallback(event => {
    setStartX(event.touches[0].clientX);
  }, []);

  const handleTouchEnd = useCallback(
    event => {
      if (startX !== null) {
        const endX = event.changedTouches[0].clientX;
        const deltaX = startX - endX;

        // Check if the absolute swipe distance is greater than the threshold
        if (deltaX > 100) {
          setIsOpen(false);
        }
      }
      setStartX(null);
    },
    [startX, setStartX, setIsOpen],
  );

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer(true)}
              sx={{ mr: 2 }}
            >
              <Menu />
            </IconButton>
            {children}
            <DarkModeToggle />
          </div>
        </Toolbar>
      </AppBar>
      {/* <Drawer anchor="top" open={isOpen} onClose={toggleDrawer(false)}> */}
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <List sx={{ minWidth: '300px' }}>
          {isAuthenticated && (
            <>
              {user?.is_staff && (
                <ListItem component={CustomLink} href="/api/admin" underline="hover" key="admin">
                  <ListItemIcon>
                    <AdminPanelSettings />
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                </ListItem>
              )}
              <ListItem component={CustomLink} href="/docs" underline="hover">
                <ListItemIcon>
                  <Description />
                </ListItemIcon>
                <ListItemText primary="Docs" />
              </ListItem>
              {checkGroups(groups, ['IT']) && (
                <ListItem component={CustomLink} href="/jhsc" underline="hover">
                  <ListItemIcon>
                    <HealthAndSafety />
                  </ListItemIcon>
                  <ListItemText primary="Health and Safety" />
                </ListItem>
              )}
              <SubNav primary="Office" icon={<Desk />} items={getCommonItems(groups)} />
              {checkGroups(groups, ['Forms']) && (
                <SubNav primary="Forms" icon={<Feed />} items={formItems} />
              )}
              {checkGroups(groups, ['Schedule']) && (
                <ListItem component={CustomLink} href="/schedule" underline="hover">
                  <ListItemIcon>
                    <CalendarMonth />
                  </ListItemIcon>
                  <ListItemText primary="Schedule" />
                </ListItem>
              )}
              {checkGroups(groups, ['Service']) && (
                <SubNav primary="Service" icon={<ListAlt />} items={serviceItems} />
              )}
              {checkGroups(groups, ['Custom']) && (
                <ListItem component={CustomLink} href="/office/custom" underline="hover">
                  <ListItemIcon>
                    <Handyman />
                  </ListItemIcon>
                  <ListItemText primary="Custom" />
                </ListItem>
              )}
              {checkGroups(groups, ['Line', 'BackOrders']) && (
                <SubNavWithChildren primary="The Line" icon={<Mediation />}>
                  {checkGroups(groups, ['Line']) && (
                    <ListItem
                      component={CustomLink}
                      href="/work-orders/assembly-line"
                      underline="hover"
                    >
                      <ListItemText inset primary="Assembly Line" />
                    </ListItem>
                  )}
                  {checkGroups(groups, ['Line']) && (
                    <ListItem
                      component={CustomLink}
                      href="/work-orders/finish-line"
                      underline="hover"
                    >
                      <ListItemText inset primary="The Line" />
                    </ListItem>
                  )}
                  {checkGroups(groups, ['Line']) && (
                    <ListItem component={CustomLink} href="/doors" underline="hover">
                      <ListItemText inset primary="Doors" />
                    </ListItem>
                  )}
                  {checkGroups(groups, ['BackOrders']) && (
                    <ListItem component={CustomLink} href="/back-orders" underline="hover">
                      <ListItemText inset primary="Back Orders" />
                    </ListItem>
                  )}
                </SubNavWithChildren>
              )}
              {checkGroups(groups, ['Ormont']) && (
                <SubNav primary="Ormont" icon={<Kitchen />} items={getOrmontItems(groups)} />
              )}
              {checkGroups(groups, ['Shipping']) && (
                <ListItem component={CustomLink} href="/shipping" underline="hover">
                  <ListItemIcon>
                    <LocalShipping />
                  </ListItemIcon>
                  <ListItemText primary="Shipping" />
                </ListItem>
              )}
            </>
          )}
          <ListItem
            component={CustomLink}
            href={isAuthenticated ? '/api/logout' : '/api/login'}
            underline="hover"
          >
            <ListItemIcon>{isAuthenticated ? <Logout /> : <Login />}</ListItemIcon>
            <ListItemText primary={isAuthenticated ? 'Logout' : 'Login'} />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    is_superuser: PropTypes.bool,
    is_staff: PropTypes.bool,
    full_name: PropTypes.string,
  }),
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};
